import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import FoodMenu from './FoodMenu.jsx';
import Home from './Home.jsx';
import {Switch} from 'react-router';

export default class Main extends Component {
  render() {
    return (
      <main>
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route path='/meniu' component={FoodMenu}/>
        </Switch>
      </main>
    );
  }
}