import React, {Component} from 'react';
import {Link} from 'react-router';
import Main from './Main.jsx';
import Header from './Header.jsx';

export default class App extends Component {
  render() {
    return (
      <div>
        <Header />
        <Main />
      </div>
    );
  }
}