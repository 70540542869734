import React from 'react'
import {Link} from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './Header.scss';

const Header = () => (
  <Navbar bg="light" variant="light">
    <Navbar.Brand><Link to='/' className="link">Gateste cu Mimi</Link></Navbar.Brand>
    <Nav className="mr-auto">
      <Link to='/' className="link">Acasa</Link>
      <Link to='/meniu' className="link">Meniu</Link>
    </Nav>
  </Navbar>
);

export default Header;
