import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export default class Home extends Component {
    render() {
        return (
            <div className="content-wrapper">
                <div className="content">
                    <img src="assets/logo.jpg" className="logo"/>

                    <h1>Gateste sanatos. Comanda mancare sanatoasa</h1>
                    <p>Pagina noastra este in constructie.</p>

                    <ul className="ch-grid">
                        <li>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/gatestecumimi">
                                <div className="ch-item ch-img-1">
                                    <div className="ch-info">
                                        <h3>Comenzi si detalii</h3>
                                        <p>Comenzi de mancare la domiciliu sau pentru ocazii speciale</p>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" rel="noopener noreferrer"
                               href="https://www.youtube.com/channel/UCGni-ujijyj-0fT1a3mEO5w">
                                <div className="ch-item ch-img-2">
                                    <div className="ch-info">
                                        <h3>Retete sanatoase, gustoase si rapide</h3>
                                        <p>Postam in fiecare luni retete vegane</p>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
