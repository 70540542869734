// Application entrypoint.

// Load up the application styles
import {BrowserRouter} from 'react-router-dom';

require('../styles/application.scss');

// Render the top-level React component
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';

ReactDOM.render((
  <BrowserRouter>
    <App/>
  </BrowserRouter>
), document.getElementById('react-root'));
