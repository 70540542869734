import React, {Component} from 'react';
import GetSheetDone from 'get-sheet-done';
import Table from 'react-bootstrap/Table';
import './FoodMenu.scss';

const DOC_KEY = '1BOfVBbq06JsCjdveWlcN151olb-PSf24Vo3KtbXfaQ4';

export default class FoodMenu extends Component {
  state = {head: null, data: null};

  componentDidMount() {
    GetSheetDone.raw(DOC_KEY).then(sheet => {
      this.setState({head: sheet.data[0], data: sheet.data.splice(1)});
    })
  }

  render() {
    return (
      <Table striped bordered hover>
        <thead>
        <tr>
          {this.state.head && this.state.head.map((element, index) => <th key={index}>{element}</th>)}
        </tr>
        </thead>
        <tbody>
        {this.state.data && this.state.data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((element, index) => (
              <td key={index} className={this.getClassName(this.state.head[index])}>{element}</td>
            ))}
          </tr>
        ))}
        </tbody>
      </Table>
    );
  }

  getClassName(columnName) {
    return columnName.replace(/\s/g, '').toLowerCase();
  }
}